import spicAndSpanIcon from "../../assets/icons/aggregators/spic-and-span.svg";

export const AGGREGATOR_OPTIONS = [
  {
    label: "Spic and Span",
    value: "Spic and Span",
    icon: spicAndSpanIcon,
    color: "#f0d218",
  },
];
